import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { Category } from 'src/app/entities/category';
import { preExistingCategoryFields } from 'src/app/entities/util';
import { DialogCommunicator } from '../../../dialogs/dialog-communicator';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'atom-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.css']
})
export class CategoryDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  emitter: EventEmitter;
  defaulttype = "text"
  category: any;
  fieldname = {}
  key: string
  cities = []
  types = [{ key: "COMPANY", value: "Individual" }, { key: "INDIVIDUAL", value: "Individual" }]
  fieldTypes = ['text', 'number', 'tel', 'calendar', 'turnover', 'sector dropdown', 'postal codes dropdown', 'region dropdown', 'department dropdown', 'city dropdown', 'municipality dropdown', 'district dropdown', 'branch dropdown', 'activity dropdown', 'activity detail dropdown', 'custom dropdown', 'header', 'sub-header']
  addableFieldTypes = ['text', 'number', 'tel', 'calendar', 'turnover', 'sector dropdown', 'postal codes dropdown', 'region dropdown', 'department dropdown', 'city dropdown', 'municipality dropdown', 'district dropdown', 'branch dropdown', 'activity dropdown', 'activity detail dropdown', 'custom dropdown', 'header', 'sub-header']
  required = false;
  changeable = false;
  addable = false;
  duplicate = false;
  nonFinalized = false;
  columnStyle = false;
  multiple: false;
  customSelectItems = []
  languages = ['en', 'fr']
  constructor(
    public dialogRef: MatDialogRef<CategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService,
    public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.category = new Category()
    if (data) {
      this.category = JSON.parse(JSON.stringify(data));
      this.category['class'] = 'CATEGORY'
    }else{
      this.category['additionalFields'] = []
      preExistingCategoryFields.forEach(elem => this.category['additionalFields'].push(elem))
    }

    this.setEntity(this.category);
    this.setConfirmationDialog(this.confirmationDialog)
  }
  deleteAddableField(key, field){
    if (field){
      field['fields'] = field['fields'].filter(f=>f.key!=key)
    }
    else{
      this.addableField['fields'] = this.addableField['fields'].filter(f=>f.key!=key)
      if (this.addableField['fields'].length===0){
        this.addableField = undefined
      }
    }
    
  }
  addField() {
      
    if (this.addableField){
      this.addableField.required = this.required, 
      this.addableField.changeable = this.changeable, 
      this.addableField.duplicate = this.duplicate, 
      this.addableField.nonFinalized = this.nonFinalized 
      this.addableField.columnStyle = this.columnStyle
      this.category.additionalFields.push(this.addableField)
      this.addableField = undefined
      return
    }
    if (this.fieldname) {
      if (!this.category.additionalFields) {
        this.category.additionalFields = []
      }
      let fieldKey = this.key

      if (!fieldKey) {
        var keyIncrement = 1
        fieldKey = "key_" + keyIncrement;

        while (this.category.additionalFields.find(elem => elem.key === fieldKey) !== undefined) {
          keyIncrement++;
          fieldKey = "key_" + keyIncrement;
        }
      }


      this.category.additionalFields.push({ key: fieldKey, name: this.fieldname, type: this.defaulttype, required: this.required, changeable: this.changeable, duplicate: this.duplicate, nonFinalized: this.nonFinalized, columnStyle: this.columnStyle })
      if (['custom dropdown', 'sector dropdown', 'postal codes dropdown', 'region dropdown', 'department dropdown', 'city dropdown', 'municipality dropdown', 'district dropdown', 'branch dropdown', 'activity dropdown', 'activity detail dropdown'].includes(this.defaulttype) && this.multiple) {
        this.category.additionalFields[this.category.additionalFields.length - 1].multiple = this.multiple
      }

      if (this.defaulttype === 'custom dropdown')
        this.category.additionalFields[this.category.additionalFields.length - 1].selectItems = this.customSelectItems

      this.fieldname = {}
      this.required = false
      this.changeable = false
      this.duplicate = false
      this.nonFinalized = false
      this.columnStyle = false
      this.key = null
      this.multiple = null
      this.customSelectItems = []
    }
  }
  addableField
  addAddableField(field) {
    if (field){
      var addF = { key: field['key']+'_'+(field['fields'].length+1), name: field['name'], type: field.type, multiple: field.multiple}
      field['name'] = {en: '', fr: ''}
      field['type'] = 'addable'
      field['fields'][0].push(addF)
    }
    else if (this.fieldname) {
      if (!this.addableField){
        let fieldKey = this.key

        if (!fieldKey) {
          var keyIncrement = 1
          fieldKey = "key_" + keyIncrement;
  
          if (this.category.additionalFields){
            while (this.category.additionalFields.find(elem => elem.key === fieldKey) !== undefined) {
              keyIncrement++;
              fieldKey = "key_" + keyIncrement;
            }
          }else{
            this.category.additionalFields = []
          }
          
        }
        this.addableField = {key : fieldKey, name: {en: '', fr: ''}, type: 'addable' }
        this.addableField['fields'] = []
        this.addableField['fields'].push([])
      }

      this.addableField['fields'][0].push(
        { key: this.addableField['key']+'_'+(this.addableField['fields'][0].length+1), name: this.fieldname, type: this.defaulttype, multiple: this.multiple}
      )
      this.fieldname = {}
      this.required = false
      this.changeable = false
      this.duplicate = false
      this.nonFinalized = false
      this.columnStyle = false
      this.key = null
      this.multiple = null
      this.customSelectItems = []
    }

  }

  setListItems(items, field) {
    if (field)
      field.selectItems = items
    else this.customSelectItems = items
  }

  updateField(field, index) {
    this.category.additionalFields.splice(index, 1, { ...field })
  }

  deleteField(index) {
    this.category.additionalFields.splice(index, 1)
  }
  handleAddableSelectionChange(event, model) {
    // if (model) {
    //   model.type = event.value
    // } else
    //   this.defaulttype = event.value
  }

  handleSelectionChange(event, model) {
    if (model) {
      model.type = event.value
    } else
      this.defaulttype = event.value
  }


  moveElement(i, direction) {
    if (direction === 'up') {
      const temp = this.category.additionalFields[i - 1];
      this.category.additionalFields[i - 1] = this.category.additionalFields[i];
      this.category.additionalFields[i] = temp
    }
    if (direction === 'down') {
      const temp = this.category.additionalFields[i + 1];
      this.category.additionalFields[i + 1] = this.category.additionalFields[i];
      this.category.additionalFields[i] = temp
    }
  }

  ngOnInit() { }
}